<template>
    <div class="bg-white shadow-lg rounded-lg">
        <div class="pt-10 ml-5">
            <button
                v-permission="['create']"
                class="rounded-lg border-orange-500 border text-orange-500 px-3 py-2 hover:bg-orange-500 hover:text-white duration-500 tranistion"
                @click.prevent="openDialog">
                <i class="el-icon-plus text-lg"></i>新增
            </button>
        </div>
        <el-dialog
            :visible.sync="showDialog"
            :close-on-click-modal="true"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            @close="closeDialog">
            <label>錯誤代碼</label>
            <el-input v-model="errorKey"></el-input>
            <button class="blue-btn" @click.prevent="addColumn">新增</button>
        </el-dialog>
        <el-form ref="form" :model="form" :rules="rules">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>key</th>
                        <th>HttpStatus</th>
                        <th>ErrorCode</th>
                        <th>Type</th>
                        <th>Message</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody v-if="Object.keys(form).length > 0">
                    <tr v-for="(data, key) in form" :key="key">
                        <td class="w-[50px] text-center cursor-pointer" @click="dropColmun(key)">
                            {{ key }}
                            <i class="icofont-ui-delete text-orange-600 text-2xl"></i>
                        </td>
                        <td
                            v-for="(keyData, key2) in form[key]"
                            :key="key2"
                            :class="key2 === 'message' ? 'min-w-[300px] 2xl:w-[500px] lg:w-[350px]' : ''">
                            <el-form-item v-if="key2 !== 'message'" class="mb-0" :prop="`${key}.${key2}`">
                                <el-input v-if="key2 !== 'description'" v-model="form[key][key2]"></el-input>
                                <el-input v-if="key2 === 'description'" v-model="form[key][key2]" type="textarea"></el-input>
                            </el-form-item>
                            <div v-if="key2 === 'message'">
                                <el-form-item
                                    v-for="(message, key3) in form[key]['message']"
                                    :key="key3"
                                    class="mb-0"
                                    :prop="`${key}.${key2}.${key3}`">
                                    <label class="mb-0 leading-none">
                                        {{ key3 }}
                                        <el-input v-model="form[key][key2][key3]"></el-input>
                                    </label>
                                </el-form-item>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </el-form>
        <div class="my-10 mr-5 text-right">
            <el-button v-permission="['update']" class="mb-10" type="primary" native-type="submit" @click.prevent="onSubmit('form')">
                更新
            </el-button>
        </div>
    </div>
</template>

<script>
import firebase, { firebaseConnectRef } from "../../plugins/firebase";
export default {
    data() {
        return {
            form: {},
            rules: {},
            datas: {},
            langsRef: firebaseConnectRef("langs"),
            // 打開彈窗
            showDialog: false,
            errorKey: null,
        };
    },
    methods: {
        // 設定表單資料
        setDefault(datas) {
            Object.keys(datas).forEach((objKey) => {
                this.$set(this.form, objKey, {
                    status: datas[objKey].status,
                    errorCode: datas[objKey].errorCode,
                    type: datas[objKey].type,
                    message: datas[objKey].message,
                    description: datas[objKey].description,
                });
                Object.keys(this.form[objKey]["message"]).forEach(() => {
                    this.$set(this.form[objKey], "message", {
                        tw: this.form[objKey]["message"]["tw"],
                        en: this.form[objKey]["message"]["en"],
                    });
                });
            });
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.sendData();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 儲存與更新錯誤訊息
        async sendData() {
            try {
                // 新增與更新錯誤訊息
                await firebase
                    .database()
                    .ref("langs/errorStatus")
                    .update({ ...this.form });
                this.getList();
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
        // 取得列表
        async getList() {
            try {
                const data = await this.langsRef.child("errorStatus").get();
                // 判斷是否有訊息
                if (data.exists()) {
                    this.form = {};
                    this.setDefault(data.val());
                }
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得數據失敗",
                });
            }
        },
        // 打開彈窗
        openDialog() {
            this.showDialog = true;
        },
        // 新增欄位
        addColumn() {
            // 預設表單資料
            function setDefaultData() {
                this.status = null;
                this.errorCode = null;
                this.type = "";
                this.message = {
                    tw: "",
                    en: "",
                };
                this.description = "";
            }
            const defaultData = new setDefaultData();
            //如果沒有輸入值時 不作動
            if (this.$isEmpty(this.errorKey)) {
                return;
            }
            this.$set(this.form, this.errorKey, defaultData);
            this.closeDialog();
        },
        /**
         * 刪除欄位
         * @param { type String(字串) } key 刪除key
         */
        async dropColmun(key) {
            try {
                await this.$confirm(`刪除此${key}錯誤代碼?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                this.$delete(this.form, key);
                firebase
                    .database()
                    .ref("langs/errorStatus/" + key)
                    .remove();
                this.$message({
                    type: "success",
                    message: "刪除成功",
                });
            } catch (err) {
                console.log(err);
            }
        },
        // 關閉彈窗
        closeDialog() {
            this.errorKey = null;
            // 關閉彈窗
            this.showDialog = false;
        },
    },
    created() {
        this.getList();
    },
};
</script>
